<template>
  <div class="b-table__header">
    <div class="b-table__filter">
      <date-picker
        v-model="filter_date_range"
        :clearable="false"
        type="daterange"
        :open="datepicker_open"
        :editable="false"
        format="dd.MM.yyyy"
        class="elm-calendar hidden-picker"
        placeholder="Рабочий период"
        @on-change="setPeriod({ period: 'custom' })"
        @on-clickoutside="clickedOutside"
      />
      <b-dropdown
        no-caret
        variant="none"
        :disabled="datepicker_open"
      >
        <template #button-content>
          <div
            style="
              background: white;
              padding: 8px 10px;
              display: flex;
              flex-direction: row;
              height: 32px;
              border: 1px solid #bcbcbc;
            "
          >
            <span style="color: black">{{ getPeriod }}</span>
            <img
              src="/img/icons/calendar-dark.svg"
              alt="calendar"
              class="ml-2"
            />
          </div>
        </template>
        <b-dropdown-item
          class="sm-dropdown"
          @click.stop="datepicker_open = true"
        >
          Указать период
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'today' })"
        >
          Сегодня
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'yesterday' })"
        >
          Вчера
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'week' })"
        >
          Текущая неделя
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'lweek' })"
        >
          Прошлая неделя
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'month' })"
        >
          Текущий месяц
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'lmonth' })"
        >
          Прошлый месяц
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: 'lyear' })"
        >
          Последние 365 дней
        </b-dropdown-item>
        <b-dropdown-item
          class="sm-dropdown"
          @click="setPeriod({ period: null })"
        >
          Все время
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <b-button
      v-b-toggle.incoming-sidebar
      class="filters-btn"
      variant="outline"
    >
      <img
        src="../../../../public/img/icons/filter.svg"
        alt=""
      />
      Фильтры
      <div
        v-if="countFilters"
        class="count-filters"
      >
        {{ countFilters }}
      </div>
    </b-button>
    <div class="b-table__actions ml-auto">
      <b-overlay
        :show="file_loading"
        opacity="0.6"
        spinner-variant="primary"
        spinner-small
      >
        <b-dropdown
          no-caret
          variant="primary"
        >
          <template #button-content>
            <img
              src="/img/icons/btn-plus.svg"
              class="mr"
              alt=""
            />
            Добавить документ
          </template>
          <b-dropdown-item @click="createDocument('outgoing')">
            <div class="d-flex">
              <img
                class="dropdown-icon"
                src="/img/icons/payment/outgoing-icon.svg"
              />
              <div class="dropdown-text">Исходящий платёж</div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click="createDocument('incoming')">
            <div class="d-flex">
              <img
                class="dropdown-icon"
                src="/img/icons/payment/incoming-icon.svg"
              />
              <div class="dropdown-text">Входящий платёж</div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click="choose_files">
            <div class="d-flex">
              <img
                class="dropdown-icon"
                src="/img/icons/payment/bank.svg"
              />
              <div class="dropdown-text">Загрузить из банка</div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </b-overlay>

      <input
        id="fileUpload"
        type="file"
        hidden
        :v-model="file"
        @input="set_file"
      />
    </div>
    <filters-sidebar
      :table_name="page_type"
      :status_list="status_list"
      :incoming_operations="incoming_operations"
      :supplier_list="supplier_list"
      :storage_list="storage_list"
      :types_list="types_list"
      :owners_list="owners_list"
      :branch_list="branch_list"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import FiltersSidebar from '@/components/sidebar/FiltersSidebar'

  export default {
    name: 'DocumentHeaderPayment',
    components: {
      FiltersSidebar
    },
    props: {
      page_type: {
        type: String,
        default: ''
      },
      file: {
        default: null
      },
      set_file: {
        type: Function,
        default: () => {}
      },
      choose_files: {
        type: Function,
        default: () => {}
      },
      filters: Boolean,
      file_loading: Boolean
    },
    apollo: {
      Suppliers: {
        query: require('../gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.supplier_list = data.Suppliers || []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      Storages: {
        query: require('../gql/getStorages.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storage_list = data.Storages || []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      UserFromCompany: {
        query: require('../gql/getOwners.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.owners_list = data.UserFromCompany || []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data: () => ({
      filter_date_range: [],
      selected_type: null,
      datepicker_open: false,
      moddate: new Date(),
      creating: false,
      suppliers: [],
      storages: [],
      supplier_list: [],
      storage_list: [],
      operation: [],
      owners_list: [],
      incoming_operations: [
        { key: 'income', label: 'Приемка' },
        { key: 'bonus', label: 'Бонус' }
      ],
      status_list: [
        { id: 'fixed', name: 'Проведен' },
        { id: 'no_fixed', name: 'Черновик' }
      ],
      types_list: [
        { id: 'entersight', name: 'Энтерсайт' },
        { id: 'crptech', name: 'Честный знак' },
        { id: 'egais', name: 'Егаис' },
        { id: 'evotor', name: 'Эвотор' },
        { id: 'sbis', name: 'СБИС' }
      ]
    }),

    computed: {
      ...mapGetters({
        dateRange: 'operprocess/getDateRange',
        currentBranch: 'settings/getCurrentBranch',
        getFilters: 'payment/getFilters',
        branch_list: 'settings/getBranchList'
      }),
      countFilters() {
        return (
          (this.getFilters.supplier?.length > 0 ? 1 : 0) +
          (this.getFilters.storages?.length > 0 ? 1 : 0) +
          (this.getFilters.operation?.length > 0 ? 1 : 0) +
          (this.getFilters.status?.length > 0 ? 1 : 0) +
          (this.getFilters.owners?.length > 0 ? 1 : 0) +
          (this.getFilters.types?.length > 0 ? 1 : 0) +
          (this.getFilters.branches?.length > 0 ? 1 : 0)
        )
      },
      getPeriod() {
        switch (this.selected_type) {
          case 'today': {
            return 'Сегодня'
          }
          case 'yesterday': {
            return 'Вчера'
          }
          case 'week': {
            return 'Текущая неделя'
          }
          case 'lweek': {
            return 'Прошлая неделя'
          }
          case 'month': {
            return 'Текущий месяц'
          }
          case 'lmonth': {
            return 'Прошлый месяц'
          }
          case 'lyear': {
            return 'Последние 365 дней'
          }
          case 'custom': {
            const start = new Intl.DateTimeFormat('ru-RU').format(
              this.filter_date_range[0] ? new Date(this.filter_date_range[0]) : new Date()
            )
            const end = new Intl.DateTimeFormat('ru-RU').format(
              this.filter_date_range[1] ? new Date(this.filter_date_range[1]) : new Date()
            )
            return start + ' - ' + end
          }
          default: {
            return 'За все время'
          }
        }
      }
    },

    mounted() {
      if (this.dateRange) {
        this.filter_date_range = this.dateRange.date
        this.setPeriod({ period: this.dateRange.type })
        this.updateDateRange(this.filter_date_range)
      }
    },

    methods: {
      ...mapActions({
        setDateRange: 'operprocess/setDateRange'
      }),

      clickedOutside() {
        this.datepicker_open = false
      },

      createDocument(type) {
        this.creating = true
        let variables = {
          input: {
            branches: [this.currentBranch.id],
            period: 'before',
            operation: `${type}_cashless`
          }
        }
        this.$apollo
          .mutate({
            fetchPolicy: 'no-cache',
            mutation: require(`../gql/CreateMoneyHead.graphql`),
            variables
          })
          .then(({ data }) => {
            this.$emit('create_doc')
            this.$router.push({
              name: `payment.docs.id`,
              params: { id: data.CreateMoneyHead.id }
            })
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`При создании документа произошла ошибка. Попробуйте еще раз`)
          })
          .finally(() => {
            this.creating = false
          })
      },

      setPeriod({ period }) {
        switch (period) {
          case 'today': {
            this.filter_date_range[0] = new Date(new Date().setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date().setHours(23, 59, 59))
            break
          }
          case 'yesterday': {
            const today = new Date()
            this.filter_date_range[0] = new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(23, 59, 59))
            break
          }
          case 'week': {
            let curr = new Date()
            let first = curr.getDate() - curr.getDay() + 1
            let last = first + 6
            this.filter_date_range[0] = new Date(new Date(curr.setDate(first)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(curr.setDate(last)).setHours(23, 59, 59))
            break
          }
          case 'lweek': {
            let curr = new Date()
            let first = curr.getDate() - curr.getDay() - 6
            let last = first + 6
            this.filter_date_range[0] = new Date(new Date(curr.setDate(first)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(curr.setDate(last)).setHours(23, 59, 59))
            break
          }
          case 'month': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear(), curr.getMonth(), 1, 0, 0, 0)
            this.filter_date_range[1] = new Date(curr.getFullYear(), curr.getMonth() + 1, 0, 23, 59, 59)
            break
          }
          case 'lmonth': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear(), curr.getMonth() - 1, 1, 0, 0, 0)
            this.filter_date_range[1] = new Date(curr.getFullYear(), curr.getMonth(), 0, 23, 59, 59)
            break
          }
          case 'lyear': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear() - 1, curr.getMonth(), curr.getDay(), 0, 0, 0)
            this.filter_date_range[1] = new Date()
            break
          }
          case 'custom': {
            // this.filter_date_range[1]?.setHours(23)
            // this.filter_date_range[1]?.setMinutes(59)
            // this.filter_date_range[1]?.setSeconds(59)
            let dt = new Date(this.filter_date_range[1])
            dt.setHours(23, 59, 59)
            this.filter_date_range[1] = dt
            this.datepicker_open = false
            this.moddate = new Date()
            // const today = new Date()
            // if (isMount && today.getDate() !== new Date(this.getPeriod.moddate).getDate()) {
            //   this.setPeriod({ period: null })
            // }
            break
          }
          default: {
            this.filter_date_range = []
            break
          }
        }
        this.selected_type = period
        this.updateDateRange()
      },
      updateDateRange() {
        this.setDateRange({
          type: this.selected_type,
          date: this.filter_date_range,
          moddate: this.moddate
        })
        this.$emit('date_range', this.filter_date_range)
      },
      updateFilters() {
        this.$emit('update_filters', {
          operation: this.operation,
          storage: this.storages,
          supplier: this.suppliers
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .inc-filters {
      flex-wrap: wrap;
      grid-gap: 0.5rem;
    }

    .inc-select-picker {
      width: 230px;

      .ivu-tag {
        max-width: 110px;
      }
    }

    .datepicker-range {
      width: 210px;
    }

    .sm-dropdown > a {
      padding: 4px 12px !important;
    }

    .hidden-picker > .ivu-date-picker-rel {
      max-width: 0px !important;
    }

    .hidden-picker > div > div > span {
      display: none;
    }
  }

  .b-table__header {
    display: flex;
  }

  .not-collapsed img {
    transform: rotate(0deg) !important;
  }

  .collapsed img {
    transform: rotate(0deg) !important;
  }

  .filters-btn {
    background: #fff;
    margin-left: 12px;
    border: 1px solid #bcbcbc;
    border-radius: 4px;
    align-items: center;
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #313131;

    .count-filters {
      font-family: 'Gilroy-Medium', sans-serif;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 12px;
      color: #fff;
      border-radius: 4px;
      background-color: #00a3ff;
    }

    img {
      margin-right: 12px;
    }
  }

  .hidden-picker {
    max-width: 0px !important;
  }

  .hidden-picker > div > div > input {
    display: none;
  }

  .dropdown-icon {
    margin-right: 10px;
  }
  .dropdown-text {
    margin-right: 25px;
  }
</style>
