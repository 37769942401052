var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-filter"
  }, [_c('b-form', {
    staticClass: "pos-r form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.handlerPagination.apply(null, arguments);
      }
    }
  }, [_c('b-form-input', {
    staticClass: "filter-search",
    attrs: {
      "type": "text",
      "placeholder": _vm.placeholder
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm.search.length > 0 ? _c('b-button', {
    staticClass: "btn-clear-search-str",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1), _c('b-dropdown', {
    staticClass: "ml-3",
    staticStyle: {
      "height": "32px",
      "width": "32px"
    },
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/settings.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.sort-table-modal",
      modifiers: {
        "sort-table-modal": true
      }
    }]
  }, [_vm._v(" Изменить столбцы ")])], 1), _c('div', {
    staticClass: "ml-auto d-flex align-items-center"
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_vm._v("Сумма: " + _vm._s(_vm.formatPrice(_vm.total.pur_sum)) + " ₽")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }