var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "payment-doc"
  }, [_c('document-header-payment', {
    attrs: {
      "filters": "",
      "page_type": "payment",
      "set_file": _vm.setFile,
      "file_loading": _vm.file_loading,
      "choose_files": _vm.chooseFiles
    },
    on: {
      "date_range": _vm.updateDateRange,
      "update_filters": _vm.update_filters,
      "create_doc": _vm.createDoc
    }
  }), _c('div', {
    staticClass: "table-content"
  }, [_c('div', [_c('documents-table-filter-payment', {
    staticClass: "table-filter",
    attrs: {
      "total": _vm.total,
      "placeholder": "Поиск"
    }
  })], 1), _c('resizable-table', {
    staticClass: "payment-doc-table",
    staticStyle: {
      "flex": "1"
    },
    attrs: {
      "table_name": "payment-doc-table",
      "empty_text": "Ничего не найдено",
      "items": _vm.documents,
      "default_fields": _vm.fields,
      "busy": false
    },
    on: {
      "row-click": _vm.rowClick
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox')];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (data) {
        return [_c('div', {
          staticClass: "b-status",
          class: {
            success: data.item.fixed
          }
        }), _c('e-checkbox', {
          attrs: {
            "checked": _vm.checked(data.value),
            "value": data.value
          },
          on: {
            "click": function ($event) {
              return _vm.selectBranch(data.value);
            }
          }
        })];
      }
    }, {
      key: "body_type",
      fn: function (data) {
        return [_c('ext-system-logo', {
          attrs: {
            "ext_system": data.value
          }
        })];
      }
    }, {
      key: "body_date",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.convertDate(data.item.date)) + " ")];
      }
    }, {
      key: "body_number",
      fn: function (data) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(_vm._s(data.item.number))])];
      }
    }, {
      key: "body_pay_num",
      fn: function (data) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(_vm._s(data.item.pay_num))])];
      }
    }, {
      key: "body_fixed",
      fn: function (data) {
        return [_c('b-badge', {
          class: {
            success: data.item.fixed
          }
        }, [_vm._v(" " + _vm._s(data.item.fixed ? 'Проведен' : 'Черновик') + " ")])];
      }
    }, {
      key: "body_operation",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.mapping[data.value]) + " ")];
      }
    }, {
      key: "body_sum",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(_vm._s(_vm.formatMoney(item.sum || 0)))])];
      }
    }, {
      key: "body_payed_sum",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(_vm._s(_vm.formatMoney(item.payed_sum || 0)))])];
      }
    }, {
      key: "body_supplier",
      fn: function (data) {
        return [_c('entity-info', {
          attrs: {
            "entity": data.value
          }
        })];
      }
    }, {
      key: "body_status",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [!item.status ? _c('div', [_vm._v("--")]) : _vm._e()];
      }
    }, {
      key: "body_entity",
      fn: function (data) {
        return [_c('entity-info', {
          attrs: {
            "entity": data.value
          }
        })];
      }
    }, {
      key: "body_owner",
      fn: function (data) {
        var _data$value, _data$value2;

        return [_vm._v(" " + _vm._s((((_data$value = data.value) === null || _data$value === void 0 ? void 0 : _data$value.first_name) || '') + ' ' + (((_data$value2 = data.value) === null || _data$value2 === void 0 ? void 0 : _data$value2.last_name) || '')) + " ")];
      }
    }, {
      key: "body_supplier_acc",
      fn: function (data) {
        return [_c('bank-info', {
          attrs: {
            "account": data.value
          }
        })];
      }
    }, {
      key: "body_entity_acc",
      fn: function (data) {
        return [_c('bank-info', {
          attrs: {
            "account": data.value
          }
        })];
      }
    }, {
      key: "body_comment",
      fn: function (data) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "comment",
          attrs: {
            "title": data.value
          }
        }, [_vm._v(" " + _vm._s(data.value) + " ")])];
      }
    }])
  }), _c('table-navbar', {
    staticClass: "payment-docs-table-navbar",
    attrs: {
      "items": _vm.selected,
      "export_to_bank": _vm.export_to_bank,
      "hide_print": ""
    },
    on: {
      "remove_items": _vm.remove
    }
  })], 1), _c('pagination', {
    attrs: {
      "total": _vm.items_total,
      "table_name": _vm.table_name
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }