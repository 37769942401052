var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-table__header"
  }, [_c('div', {
    staticClass: "b-table__filter"
  }, [_c('date-picker', {
    staticClass: "elm-calendar hidden-picker",
    attrs: {
      "clearable": false,
      "type": "daterange",
      "open": _vm.datepicker_open,
      "editable": false,
      "format": "dd.MM.yyyy",
      "placeholder": "Рабочий период"
    },
    on: {
      "on-change": function ($event) {
        return _vm.setPeriod({
          period: 'custom'
        });
      },
      "on-clickoutside": _vm.clickedOutside
    },
    model: {
      value: _vm.filter_date_range,
      callback: function ($$v) {
        _vm.filter_date_range = $$v;
      },
      expression: "filter_date_range"
    }
  }), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "none",
      "disabled": _vm.datepicker_open
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "background": "white",
            "padding": "8px 10px",
            "display": "flex",
            "flex-direction": "row",
            "height": "32px",
            "border": "1px solid #bcbcbc"
          }
        }, [_c('span', {
          staticStyle: {
            "color": "black"
          }
        }, [_vm._v(_vm._s(_vm.getPeriod))]), _c('img', {
          staticClass: "ml-2",
          attrs: {
            "src": "/img/icons/calendar-dark.svg",
            "alt": "calendar"
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.datepicker_open = true;
      }
    }
  }, [_vm._v(" Указать период ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'today'
        });
      }
    }
  }, [_vm._v(" Сегодня ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'yesterday'
        });
      }
    }
  }, [_vm._v(" Вчера ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'week'
        });
      }
    }
  }, [_vm._v(" Текущая неделя ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'lweek'
        });
      }
    }
  }, [_vm._v(" Прошлая неделя ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'month'
        });
      }
    }
  }, [_vm._v(" Текущий месяц ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'lmonth'
        });
      }
    }
  }, [_vm._v(" Прошлый месяц ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'lyear'
        });
      }
    }
  }, [_vm._v(" Последние 365 дней ")]), _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: null
        });
      }
    }
  }, [_vm._v(" Все время ")])], 1)], 1), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.incoming-sidebar",
      modifiers: {
        "incoming-sidebar": true
      }
    }],
    staticClass: "filters-btn",
    attrs: {
      "variant": "outline"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../../public/img/icons/filter.svg"),
      "alt": ""
    }
  }), _vm._v(" Фильтры "), _vm.countFilters ? _c('div', {
    staticClass: "count-filters"
  }, [_vm._v(" " + _vm._s(_vm.countFilters) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "b-table__actions ml-auto"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.file_loading,
      "opacity": "0.6",
      "spinner-variant": "primary",
      "spinner-small": ""
    }
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('img', {
          staticClass: "mr",
          attrs: {
            "src": "/img/icons/btn-plus.svg",
            "alt": ""
          }
        }), _vm._v(" Добавить документ ")];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.createDocument('outgoing');
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('img', {
    staticClass: "dropdown-icon",
    attrs: {
      "src": "/img/icons/payment/outgoing-icon.svg"
    }
  }), _c('div', {
    staticClass: "dropdown-text"
  }, [_vm._v("Исходящий платёж")])])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.createDocument('incoming');
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('img', {
    staticClass: "dropdown-icon",
    attrs: {
      "src": "/img/icons/payment/incoming-icon.svg"
    }
  }), _c('div', {
    staticClass: "dropdown-text"
  }, [_vm._v("Входящий платёж")])])]), _c('b-dropdown-item', {
    on: {
      "click": _vm.choose_files
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('img', {
    staticClass: "dropdown-icon",
    attrs: {
      "src": "/img/icons/payment/bank.svg"
    }
  }), _c('div', {
    staticClass: "dropdown-text"
  }, [_vm._v("Загрузить из банка")])])])], 1)], 1), _c('input', {
    attrs: {
      "id": "fileUpload",
      "type": "file",
      "hidden": "",
      "v-model": _vm.file
    },
    on: {
      "input": _vm.set_file
    }
  })], 1), _c('filters-sidebar', {
    attrs: {
      "table_name": _vm.page_type,
      "status_list": _vm.status_list,
      "incoming_operations": _vm.incoming_operations,
      "supplier_list": _vm.supplier_list,
      "storage_list": _vm.storage_list,
      "types_list": _vm.types_list,
      "owners_list": _vm.owners_list,
      "branch_list": _vm.branch_list
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }