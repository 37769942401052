var render = function render(){
  var _vm$account, _vm$account$bank, _vm$account2, _vm$account3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "entity-info name cursor text-left"
  }, [_vm._v(" " + _vm._s(((_vm$account = _vm.account) === null || _vm$account === void 0 ? void 0 : (_vm$account$bank = _vm$account.bank) === null || _vm$account$bank === void 0 ? void 0 : _vm$account$bank.name) || 'Не указан') + " ")]), _c('div', {
    staticClass: "entity-info-inn info table-substr text-left"
  }, [_vm._v(" " + _vm._s('БИК: ' + (((_vm$account2 = _vm.account) === null || _vm$account2 === void 0 ? void 0 : _vm$account2.bank.bic) || '–')) + " " + _vm._s(' РС: ' + (((_vm$account3 = _vm.account) === null || _vm$account3 === void 0 ? void 0 : _vm$account3.check_acc) || '–')) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }