<template>
  <div class="table-filter">
    <b-form
      class="pos-r form"
      @submit.prevent="handlerPagination"
    >
      <b-form-input
        v-model="search"
        type="text"
        class="filter-search"
        :placeholder="placeholder"
      />
      <b-button
        v-if="search.length > 0"
        class="btn-clear-search-str"
        @click.prevent="clear"
      />
    </b-form>
    <!--    <b-button v-if="load_categories" v-b-modal.categories-modal class="ml-3">-->
    <!--      Загрузка групп товаров</b-button-->
    <!--    >-->
    <b-dropdown
      no-caret
      variant="none"
      class="ml-3"
      style="height: 32px; width: 32px"
    >
      <template #button-content>
        <div class="btn-more">
          <img
            src="/img/icons/settings.svg"
            alt=""
          />
        </div>
      </template>
      <b-dropdown-item v-b-modal.sort-table-modal> Изменить столбцы </b-dropdown-item>
    </b-dropdown>
    <div class="ml-auto d-flex align-items-center">
      <div class="mr-3">Сумма: {{ formatPrice(total.pur_sum) }} ₽</div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'DocumentsTableFilterPayment',
    props: {
      placeholder: {
        type: String,
        default: () => '№ Документ или документ поставщика'
      },
      load_categories: {
        type: Boolean,
        default: false
      },
      edit_fields: {
        type: Boolean,
        default: false
      },
      table_name: {
        type: String,
        default: null
      },
      total: Object
    },
    data: () => ({
      search: ''
    }),
    beforeMount() {
      if (this.table_name) {
        this.search = this.getPagination?.[this.table_name]?.search
      }
    },

    computed: {
      ...mapGetters({ getPagination: 'settings/getPagination' })
    },

    methods: {
      handlerPagination() {
        this.$emit('handler_pagination', this.search)
      },

      clear() {
        this.search = ''
        this.$emit('clear')
      },
      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ

        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-filter {
    .form {
      width: 268px;
    }

    .filter-search {
      border-radius: 2px;
      border: 1px solid #bcbcbc;
      background: #fff;
    }

    .filter-search::placeholder {
      color: var(--text-placeholder-gray-300, #bcbcbc);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .btn-clear-search-str {
      position: absolute;
      top: 9px;
      right: 9px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      overflow: hidden;
      background: #efefef;
      padding: 0;

      &:before,
      &:after {
        content: '';
        height: 1px;
        width: 8px;
        background: #888888;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%) rotate(-45deg);
      }

      &:after {
        transform: translate(-50%) rotate(45deg);
      }
    }
  }
  .table-filter {
    display: flex;
    justify-content: flex-start;
  }
</style>
