var render = function render(){
  var _vm$entity, _vm$entity2, _vm$entity3, _vm$entity4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "entity-info name cursor text-left"
  }, [_vm._v(" " + _vm._s(((_vm$entity = _vm.entity) === null || _vm$entity === void 0 ? void 0 : _vm$entity.name) || ((_vm$entity2 = _vm.entity) === null || _vm$entity2 === void 0 ? void 0 : _vm$entity2.full_name) || 'Не указан') + " ")]), _c('div', {
    staticClass: "entity-info-inn info table-substr text-left"
  }, [_vm._v(" " + _vm._s('ИНН: ' + (((_vm$entity3 = _vm.entity) === null || _vm$entity3 === void 0 ? void 0 : _vm$entity3.inn) || '–')) + " " + _vm._s(' КПП: ' + (((_vm$entity4 = _vm.entity) === null || _vm$entity4 === void 0 ? void 0 : _vm$entity4.kpp) || '–')) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }