<template>
  <div class="payment-doc">
    <document-header-payment
      filters
      page_type="payment"
      :set_file="setFile"
      :file_loading="file_loading"
      :choose_files="chooseFiles"
      @date_range="updateDateRange"
      @update_filters="update_filters"
      @create_doc="createDoc"
    />
    <div class="table-content">
      <div>
        <documents-table-filter-payment
          class="table-filter"
          :total="total"
          placeholder="Поиск"
        />
      </div>
      <resizable-table
        style="flex: 1"
        class="payment-doc-table"
        table_name="payment-doc-table"
        empty_text="Ничего не найдено"
        :items="documents"
        :default_fields="fields"
        :busy="false"
        @row-click="rowClick"
      >
        <template #head_id>
          <e-checkbox />
        </template>

        <template #body_id="data">
          <div
            class="b-status"
            :class="{ success: data.item.fixed }"
          ></div>
          <e-checkbox
            :checked="checked(data.value)"
            :value="data.value"
            @click="selectBranch(data.value)"
          />
        </template>

        <template #body_type="data">
          <ext-system-logo :ext_system="data.value" />
        </template>

        <template #body_date="data">
          {{ convertDate(data.item.date) }}
        </template>
        <template #body_number="data">
          <div class="text-right">{{ data.item.number }}</div>
        </template>
        <template #body_pay_num="data">
          <div class="text-right">{{ data.item.pay_num }}</div>
        </template>
        <template #body_fixed="data">
          <b-badge :class="{ success: data.item.fixed }">
            {{ data.item.fixed ? 'Проведен' : 'Черновик' }}
          </b-badge>
        </template>

        <template #body_operation="data">
          {{ mapping[data.value] }}
        </template>

        <template #body_sum="{ item }">
          <div class="text-right">{{ formatMoney(item.sum || 0) }}</div>
        </template>

        <template #body_payed_sum="{ item }">
          <div class="text-right">{{ formatMoney(item.payed_sum || 0) }}</div>
        </template>

        <template #body_supplier="data">
          <entity-info :entity="data.value" />
        </template>

        <template #body_status="{ item }">
          <div v-if="!item.status">--</div>
        </template>

        <template #body_entity="data">
          <entity-info :entity="data.value" />
        </template>

        <template #body_owner="data">
          {{ (data.value?.first_name || '') + ' ' + (data.value?.last_name || '') }}
        </template>

        <template #body_supplier_acc="data">
          <bank-info :account="data.value" />
        </template>

        <template #body_entity_acc="data">
          <bank-info :account="data.value" />
        </template>

        <template #body_comment="data">
          <div
            v-b-tooltip.hover
            class="comment"
            :title="data.value"
          >
            {{ data.value }}
          </div>
        </template>
      </resizable-table>
      <table-navbar
        class="payment-docs-table-navbar"
        :items="selected"
        :export_to_bank="export_to_bank"
        hide_print
        @remove_items="remove"
      />
    </div>
    <pagination
      :total="items_total"
      :table_name="table_name"
      @change_pagination="handlerPagination"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Pagination from '@/views/shared/components/table/Pagination'
  import DocumentHeaderPayment from '@/views/payment/components/DocumentHeaderPayment.vue'
  import DocumentsTableFilterPayment from '@/views/payment/components/DocumentsTableFilterPayment.vue'
  import formatDate from '@/utils/formatDate'
  import EntityInfo from '@/views/payment/components/EntityInfo.vue'
  import ExtSystemLogo from '@/views/operational-processes/components/incoming/ExtSystemLogo.vue'
  import BankInfo from '@/views/payment/components/BankInfo.vue'
  import TableNavbar from '@/views/shared/components/table/TableNavbar.vue'
  import { http } from '@/http/http'
  import ResizableTable from '@/components/ResizableTable'
  import { formatMoney } from '@/utils/formatMoney'

  export default {
    apollo: {
      MoneyHeads: {
        fetchPolicy: 'no-cache',
        query: require('../gql/MoneyHeads.graphql'),
        variables() {
          return {
            branch: this.currentBranch?.id,
            input: {
              branch: [this.currentBranch?.id],
              skip: this.getPagination[this.table_name]?.skip || this.pagination.skip,
              take: this.getPagination[this.table_name]?.take || this.pagination.take,
              supplier: this.getFilters?.supplier.map((item) => item.id) || [],
              // storage: this.getFilters?.storages.map((item) => item.id) || [],
              storage: this.getFilters?.branches.map((item) => item.id) || [],
              owner: this.getFilters?.owners.map((item) => item.id) || [],
              fixed: this.getStatus,
              period: {
                begin: this.data_range[0] ?? null,
                end: this.data_range[1] ?? null
              }
            }
          }
        },
        result({ data }) {
          this.documents = data?.MoneyHeads?.list || []
          this.items_total = data?.MoneyHeads?.total
        }
      }
    },
    name: 'PaymentDocs',
    components: {
      TableNavbar,
      BankInfo,
      ExtSystemLogo,
      EntityInfo,
      DocumentsTableFilterPayment,
      DocumentHeaderPayment,
      Pagination,
      ResizableTable
    },
    data() {
      return {
        table_name: 'payment-docs',
        documents: [],
        selected: [],
        file_loading: false,
        mapping: {
          incoming_cashless: 'Приходный ордер',
          outgoing_cashless: 'Расходный ордер',
          bank: 'Расходный ордер'
        },
        fields: [
          {
            key: 'id',
            label: '',
            checked: true,
            width: 50
          },
          {
            key: 'type',
            label: 'Тип',
            checked: true,
            sortable: true,
            width: 70
          },
          {
            key: 'number',
            label: '№',
            checked: true,
            sortable: true,
            width: 70
          },
          {
            key: 'date',
            label: 'Дата',
            checked: true,
            sortable: true,
            width: 100
          },
          {
            key: 'pay_num',
            label: '№ Выписки',
            checked: true,
            sortable: true,
            width: 100
          },
          {
            key: 'supplier',
            label: 'Контрагент',
            checked: true,
            sortable: true,
            width: 260
          },
          {
            key: 'entity',
            label: 'Организация',
            checked: true,
            sortable: true,
            width: 260
          },
          {
            key: 'operation',
            label: 'Операция',
            checked: true,
            sortable: true,
            width: 260
          },
          {
            key: 'sum',
            label: 'Сумма, ₽',
            checked: true,
            sortable: true,
            width: 180
          },
          {
            key: 'payed_sum',
            label: 'Платёж, ₽',
            checked: true,
            sortable: true,
            width: 180
          },
          {
            key: 'fixed',
            label: 'Статус',
            checked: true,
            sortable: true,
            width: 150
          },
          {
            key: 'status',
            label: 'Состояние',
            checked: true,
            sortable: true,
            width: 150
          },
          {
            key: 'owner',
            label: 'Менеджер',
            checked: true,
            sortable: true,
            width: 150
          },
          {
            key: 'comment',
            label: 'Комментарий',
            checked: true,
            sortable: true,
            width: 150
          },
          {
            key: 'supplier_acc',
            label: 'Банк контрагента',
            checked: true,
            sortable: true,
            width: 260
          },
          {
            key: 'entity_acc',
            label: 'Банк организации',
            checked: true,
            sortable: true,
            width: 260
          }
        ],
        items_total: 0,
        pagination: {
          skip: 0,
          take: 20
        },
        total: {
          ret_sum: 0,
          pur_sum: 0,
          nds_sum: 0
        },
        file: null,
        data_range: []
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getFilters: 'payment/getFilters',
        getPagination: 'settings/getPagination'
      }),
      getStatus() {
        if (this.getFilters.status.length === 1) {
          return this.getFilters.status[0].id === 'fixed'
        }
        return null
      }
    },
    methods: {
      async remove() {
        console.log(1)
        await this.$apollo.mutate({
          mutation: require('../gql/RemoveMoneyHead.graphql'),
          variables: {
            input: {
              ids: this.selected
            }
          }
        })
        this.selected = []
        await this.$apollo.queries.MoneyHeads.refetch()
      },

      formatMoney(value) {
        return formatMoney(value, 2)
      },

      handlerPagination({ current_page = null, take = null }) {
        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take
      },

      checked(val) {
        return !!this.selected.find((obj) => obj === val)
      },
      selectBranch(val) {
        if (!this.selected.includes(val)) {
          this.selected.push(val)
        } else {
          this.selected = this.selected.filter((obj) => obj !== val)
        }
      },
      async setFile(e) {
        try {
          this.file_loading = true
          const formData = new FormData()
          const blob = new Blob([e.target.files[0]])
          formData.append('file', blob)

          await http.post('/files/import/bank_statement?branch=' + this.currentBranch.id, formData)
          await this.$apollo.queries.MoneyHeads.refetch()

          this.$noty.show('Успешно')
        } catch (e) {
          this.$noty.show('Ошибка')
        } finally {
          this.file_loading = false
        }
      },
      chooseFiles() {
        document.getElementById('fileUpload').click()
      },
      updateDateRange(date) {
        this.data_range = date
        this.$apollo.queries.MoneyHeads.refresh()
      },
      update_filters() {},
      rowClick(row) {
        this.$router.push({
          name: `payment.docs.id`,
          params: { id: row.id }
        })
      },
      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },
      async export_to_bank() {
        const { data } = await this.$apollo.query({
          query: require('../gql/ExportToBank.graphql'),
          variables: {
            ids: this.selected
          }
        })
        window.open(data.ExportToBank, '_blank')
      },
      async createDoc() {
        await this.$apollo.queries.MoneyHeads.refetch()
      }
    }
  }
</script>

<style scoped lang="scss">
  .payment-doc {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .table-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    margin-top: 15px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 2px;
    position: relative;

    .payment-docs-table-navbar {
      position: absolute;
      bottom: 4px;
      right: 5px;
      left: 5px;
    }
  }

  :deep(.payment-doc-table) {
    .badge {
      padding: 4px 12px;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      background: #efefef;
      color: #888888;

      &.success {
        background: #ebf9f5;
        color: #00cb91;
      }
    }

    td {
      position: relative;

      .b-status {
        width: 2px;
        height: 32px;
        position: absolute;
        left: 0px;
        top: 50%;
        background: #bcbcbc;
        border-radius: 0px 1px 1px 0px;
        transform: translate(0%, -50%);

        &.success {
          background: #00cb91;
        }
      }
    }
  }

  :deep(.payment-doc-table) .table-docs {
    min-height: 100% !important;
    padding-bottom: 0 !important;
    max-height: 0 !important;

    thead {
      border-top: none !important;
    }
  }

  .table-filter {
    padding: 16px;
  }

  .comment {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    box-orient: vertical;
  }
</style>
