<template>
  <div>
    <div class="entity-info name cursor text-left">
      {{ account?.bank?.name || 'Не указан' }}
    </div>
    <div class="entity-info-inn info table-substr text-left">
      {{ 'БИК: ' + (account?.bank.bic || '–') }}
      {{ '  РС: ' + (account?.check_acc || '–') }}
    </div>
  </div>
</template>
<script>
  export default {
    name: 'BankInfo',
    props: {
      account: Object
    }
  }
</script>
<style scoped lang="scss">
  .entity-info {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #313131;
    white-space: nowrap;
  }
  .entity-info-inn {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #888888;
  }
</style>
