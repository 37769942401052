<template>
  <div>
    <div class="entity-info name cursor text-left">
      {{ entity?.name || entity?.full_name || 'Не указан' }}
    </div>
    <div class="entity-info-inn info table-substr text-left">
      {{ 'ИНН: ' + (entity?.inn || '–') }}
      {{ '  КПП: ' + (entity?.kpp || '–') }}
    </div>
  </div>
</template>
<script>
  export default {
    name: 'EntityInfo',
    props: {
      entity: Object
    }
  }
</script>
<style scoped lang="scss">
  .entity-info {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #313131;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    box-orient: vertical;
  }
  .entity-info-inn {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #888888;
  }
</style>
